import { LocationTypeId } from '@/types/Configuration';

import { TimezoneObject, Timezone } from './types';

export const CELL_WIDTH = 106;
export const CELL_HEIGHT = 56;

export const TABLE_HEADER_HEIGHT = 30;
export const TIME_LABELS_HEIGHT = 24;

export const BOOKED_LABEL_MARGIN = 4;
export const SROLLBAR_SIZE = 6;

export const BUSSINESS_HOURS_START = 8;
export const BUSINESS_HOURS_END = 20;
export const ALL_HOURS_START = 0;
const ALL_HOURS_END = 24;

export const BUSINESS_HOURS_CELLS_AMOUNT =
	BUSINESS_HOURS_END - BUSSINESS_HOURS_START;
export const ALL_HOURS_CELLS_AMOUNT = ALL_HOURS_END - ALL_HOURS_START;

export const BUSINESS_START_HOUR_STRING = '08:00:00Z';
export const START_HOUR_STRING = '00:00:00Z';

export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;

export enum TimelinePeriod {
	HOUR_PERIOD = 1,
	HALF_AND_QUATER_PERIOD = 0.75,
	HALF_PERIOD = 0.5,
	QUATER_PERIOD = 0.25,
	FULL_DAY = 24,
}

export const DEFAULT_TIMEZONE: TimezoneObject = {
	timezoneDisplayName: LocationTypeId.Minsk,
	timezoneOffset: '+3:00',
	timezone: Timezone.MINSK,
};
