import { PopUp, Typography, Warning } from '@mms/mms-ui-library';

import { BOOKING_POPUP_STYLES_CONFIG } from './constants';
import { WarningPopupContainer } from './styled';
import { BookingWarningPopupProps } from './types';

export function BookingWarningPopup({
	warning,
	handleClose,
}: BookingWarningPopupProps) {
	return (
		<PopUp
			title="WARNING"
			headerIcon={<Warning />}
			onClose={handleClose}
			stylesConfig={BOOKING_POPUP_STYLES_CONFIG}
			type="approve"
			headerType="warning"
			showCloseButton
		>
			<WarningPopupContainer>
				<Typography variant="m-400">{warning}</Typography>
			</WarningPopupContainer>
		</PopUp>
	);
}
