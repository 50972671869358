import { PropsWithChildren } from 'react';

import { MinskSpacesInternalCodes } from '../../types';

import {
	MEETING_ROOMS_TAB_ID,
	MINSK_SPACE_ABBREVIATIONS,
	WARSAW_SPACE_ABBREVIATION,
} from '@/constants/booking';
import { WorkplaceInfo } from '@/queries/booking/types';
import { DeskSize } from '@/types/Booking';
import { LocationTypeId } from '@/types/Configuration';

import { MinskSpaceALayout } from './components/MinskSpaceALayout';
import { MinskSpaceBLayout } from './components/MinskSpaceBLayout';
import { MinskSpaceCLayout } from './components/MinskSpaceCLayout';
import { MinskSpaceDLayout } from './components/MinskSpaceDLayout';
import { WarsawSpaceLayout } from './components/WarsawSpaceLayout';
import {
	DeskDirectionRotateDegree,
	DeskGenerationConfig,
	OfficeDeskPosition,
	OfficeSpaceType,
	SeatGenerationConfig,
	SpaceConfiguration,
	WorkSpaceConfigurationGenerator,
} from './types';

const generateSeat = (
	left: number,
	top: number,
	rotationDegree: number,
	displayNumber: number,
	id: number
): OfficeDeskPosition => ({
	id,
	left,
	top,
	displayNumber,
	rotationDegree,
});

class SpaceGenerator {
	seats: Array<OfficeDeskPosition> = [];

	workplacesInfo: Array<WorkplaceInfo> = [];

	abbreviation: string;

	deskSize: DeskSize;

	constructor(
		workplacesInfo: Array<WorkplaceInfo>,
		abbreviation: string,
		deskSize: DeskSize
	) {
		this.seats = [];
		this.workplacesInfo = workplacesInfo;
		this.abbreviation = abbreviation;
		this.deskSize = deskSize;
	}

	generateSeats(
		configs: Array<SeatGenerationConfig>,
		layout: (props: PropsWithChildren) => React.ReactNode
	): SpaceConfiguration {
		const { seats, workplacesInfo, abbreviation, deskSize } = this;
		configs.forEach(({ func, paddings }) => {
			paddings.forEach((padding) => {
				const index = seats.length;

				if (index >= workplacesInfo.length) {
					return;
				}

				const { id, workplaceNumber } = workplacesInfo[index];

				seats.push(func(padding, workplaceNumber, id));
			});
		});

		return {
			type: OfficeSpaceType.Space,
			SpaceLayout: layout,
			seats,
			spaceAbbreviation: abbreviation,
			deskSize,
		};
	}
}

const seatHorizontalPairsAlternation = (displayNumber: number) => {
	if (displayNumber % 2 === 0) {
		return DeskDirectionRotateDegree.Left;
	}

	return DeskDirectionRotateDegree.Right;
};

const seatVerticalPairsAlternation = (displayNumber: number) => {
	if (displayNumber % 2 === 0) {
		return DeskDirectionRotateDegree.Top;
	}

	return DeskDirectionRotateDegree.Bottom;
};

const spaceAColumnsLeftPaddings = {
	1: 1306,
	2: 1192,
	3: 1145,
	4: 1024,
	5: 972,
	6: 853,
	7: 801,
	8: 672,
	9: 620,
	10: 492,
	11: 440,
	12: 319,
	13: 267,
	14: 146,
	15: 94,
};

const spaceATopPaddings = [374, 304, 234, 164];
const createSpaceAColumnGenerator =
	(left: number, rotationDegree: number) =>
	(top: number, displayNumber: number, id: number) =>
		generateSeat(left, top, rotationDegree, displayNumber, id);

export const generateMinskSpaceA: WorkSpaceConfigurationGenerator = (
	workplacesInfo
) => {
	const minskSpaceAGenerator = new SpaceGenerator(
		workplacesInfo,
		MINSK_SPACE_ABBREVIATIONS[1],
		DeskSize.Small
	);

	return minskSpaceAGenerator.generateSeats(
		[
			{
				paddings: spaceATopPaddings.slice(0, 2),
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[1],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings.slice(0, 3),
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[3],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[4],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[5],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[6],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[7],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[8],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[9],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[10],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[11],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[12],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[13],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[14],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: spaceATopPaddings,
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[15],
					DeskDirectionRotateDegree.Right
				),
			},
			{
				paddings: [2],
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[1],
					DeskDirectionRotateDegree.Left
				),
			},
			{
				paddings: [2],
				func: createSpaceAColumnGenerator(
					spaceAColumnsLeftPaddings[2],
					DeskDirectionRotateDegree.Left
				),
			},
		],
		MinskSpaceALayout
	);
};

const spaceBConfig: Array<DeskGenerationConfig> = [
	{
		left: 50,
		top: [103, 173, 243, 313, 383, 453],
		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 210,
		top: [52, 122, 192, 262, 332],
		direction: DeskDirectionRotateDegree.Right,
	},
	{
		left: 262,
		top: [52, 122, 192, 262, 332],

		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 422,
		top: [83, 153, 223, 293, 363, 433],
		direction: DeskDirectionRotateDegree.Right,
	},
];

const minskSpaceBColumnGenerator =
	(left: number, rotationDegree: DeskDirectionRotateDegree) =>
	(top: number, displayNumber: number, id: number) =>
		generateSeat(left, top, rotationDegree, displayNumber, id);

export const generateMinskSpaceB: WorkSpaceConfigurationGenerator = (
	workplacesInfo
) => {
	const minskSpaceBGenerator = new SpaceGenerator(
		workplacesInfo,
		MINSK_SPACE_ABBREVIATIONS[2],
		DeskSize.Small
	);

	return minskSpaceBGenerator.generateSeats(
		spaceBConfig.map(({ top, left, direction }) => ({
			paddings: top,
			func: minskSpaceBColumnGenerator(left, direction),
		})),
		MinskSpaceBLayout
	);
};

const spaceCConfig: Array<DeskGenerationConfig> = [
	{
		left: 8,
		top: [114, 184, 254, 324, 394],
		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 211,
		top: [148, 218, 288, 358],

		direction: DeskDirectionRotateDegree.Right,
	},
	{
		left: 264,
		top: [148, 218, 288, 358],
		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 467,
		top: [182, 252, 322, 392, 462],
		direction: DeskDirectionRotateDegree.Right,
	},
];

const minskSpaceCColumnGenerator =
	(left: number, rotationDegree: DeskDirectionRotateDegree) =>
	(top: number, displayNumber: number, id: number) =>
		generateSeat(left, top, rotationDegree, displayNumber, id);

export const generateMinskSpaceC: WorkSpaceConfigurationGenerator = (
	workplacesInfo
) => {
	const minskSpaceCGenerator = new SpaceGenerator(
		workplacesInfo,
		MINSK_SPACE_ABBREVIATIONS[3],
		DeskSize.Small
	);

	return minskSpaceCGenerator.generateSeats(
		spaceCConfig.map(({ top, left, direction }) => ({
			paddings: top,
			func: minskSpaceCColumnGenerator(left, direction),
		})),
		MinskSpaceCLayout
	);
};

const spaceDConfig: Array<DeskGenerationConfig> = [
	{
		left: 9,
		top: [66, 136, 206, 276],
		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 187,
		top: [65, 135, 205],

		direction: DeskDirectionRotateDegree.Right,
	},
	{
		left: 239,
		top: [65, 135, 205],
		direction: DeskDirectionRotateDegree.Left,
	},
	{
		left: 416,
		top: [37, 107, 177, 247, 317],
		direction: DeskDirectionRotateDegree.Right,
	},
];

const minskSpaceDColumnGenerator =
	(left: number, rotationDegree: DeskDirectionRotateDegree) =>
	(top: number, displayNumber: number, id: number) =>
		generateSeat(left, top, rotationDegree, displayNumber, id);

export const generateMinskSpaceD: WorkSpaceConfigurationGenerator = (
	workplacesInfo
) => {
	const minskSpaceDGenerator = new SpaceGenerator(
		workplacesInfo,
		MINSK_SPACE_ABBREVIATIONS[4],
		DeskSize.Small
	);

	return minskSpaceDGenerator.generateSeats(
		spaceDConfig.map(({ top, left, direction }) => ({
			paddings: top,
			func: minskSpaceDColumnGenerator(left, direction),
		})),
		MinskSpaceDLayout
	);
};

const WARSAW_LAST_COLUMN_LEFT_PADDING = 330;
const warsawLastColumn = (top: number, displayNumber: number, id: number) =>
	generateSeat(
		WARSAW_LAST_COLUMN_LEFT_PADDING,
		top,
		seatVerticalPairsAlternation(displayNumber),
		displayNumber,
		id
	);

const WARSAW_TOP_ROW_TOP_PADDING = 40;
const warsawTopRow = (left: number, displayNumber: number, id: number) =>
	generateSeat(
		left,
		WARSAW_TOP_ROW_TOP_PADDING,
		DeskDirectionRotateDegree.Top,
		displayNumber,
		id
	);

const WARSAW_BOTTOM_ROW_TOP_PADDING = 330;
const warsawBottomRow =
	(alternationCoefficient = 1) =>
	(left: number, displayNumber: number, id: number) =>
		generateSeat(
			left,
			WARSAW_BOTTOM_ROW_TOP_PADDING,
			seatHorizontalPairsAlternation(displayNumber + alternationCoefficient),
			displayNumber,
			id
		);

export const generateWarsawSpace: WorkSpaceConfigurationGenerator = (
	workplacesInfo
) => {
	const warsawGenerator = new SpaceGenerator(
		workplacesInfo,
		WARSAW_SPACE_ABBREVIATION,
		DeskSize.Large
	);

	return warsawGenerator.generateSeats(
		[
			{
				paddings: [80, 150, 228],
				func: warsawLastColumn,
			},
			{
				paddings: [230],
				func: warsawBottomRow(2),
			},
			{
				paddings: [210],
				func: warsawTopRow,
			},

			{
				paddings: [160, 70, 0],
				func: warsawBottomRow(),
			},
			{
				paddings: [70],
				func: warsawTopRow,
			},
		],
		WarsawSpaceLayout
	);
};

export const getOfficeActiveTabs = (officeLocation: LocationTypeId) => {
	if (officeLocation === LocationTypeId.Minsk) {
		return [MinskSpacesInternalCodes.SpaceA, MEETING_ROOMS_TAB_ID];
	}

	return [];
};
