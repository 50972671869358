import { BookedDataResponse } from '../types';

import { MeetingRoomType } from '@/types/Configuration';

export const createTimelineSkeleton = (
	meetingRoomsConfig: MeetingRoomType[]
): Array<BookedDataResponse> =>
	meetingRoomsConfig.map(({ id, name }) => ({
		meetingRoomId: id,
		meetingRoomName: name,
		reservations: [],
	}));
